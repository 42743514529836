jQuery(function ($) {
  'use strict'
  document.addEventListener('touchstart', function () {
  }, false)
  $(function () {
    $('body').wrapInner('<div class="wsmenucontainer" />')
    $('<div class="overlapblackbg"></div>').prependTo('.wsmenu')
    $('#wsnavtoggle').click(function () {
      $('body').toggleClass('wsactive')
    })
    $('.overlapblackbg').click(function () {
      $('body').removeClass('wsactive')
    })
    $('.wsmenu > .wsmenu-list > li').has('.sub-menu').prepend('<span class="wsmenu-click"><i class="wsmenu-arrow"></i></span>')
    $('.wsmenu > .wsmenu-list > li').has('.wsmegamenu').prepend('<span class="wsmenu-click"><i class="wsmenu-arrow"></i></span>')
    $('.wsmenu-click').click(function () {
      $(this).toggleClass('ws-activearrow').parent().siblings().children().removeClass('ws-activearrow')
      $('.wsmenu > .wsmenu-list > li > .sub-menu, .wsmegamenu').not($(this).siblings('.wsmenu > .wsmenu-list > li > .sub-menu, .wsmegamenu')).slideUp('slow')
      $(this).siblings('.sub-menu').slideToggle('slow')
      $(this).siblings('.wsmegamenu').slideToggle('slow')
    })
    $('.wsmenu > .wsmenu-list > li > ul > li').has('.sub-menu').prepend('<span class="wsmenu-click02"><i class="wsmenu-arrow"></i></span>')
    $('.wsmenu > .wsmenu-list > li > ul > li > ul > li').has('.sub-menu').prepend('<span class="wsmenu-click02"><i class="wsmenu-arrow"></i></span>')

    $('.wstabitem > li').has('.wstitemright').prepend('<span class="wsmenu-click02"><i class="wsmenu-arrow"></i></span>')
    $('.wstabitem02 > li').has('.wstbrandbottom').prepend('<span class="wsmenu-click02"><i class="wsmenu-arrow"></i></span>')

    $('.wsmenu-click02').click(function () {
      $(this).children('.wsmenu-arrow').toggleClass('wsmenu-rotate')
      $(this).siblings('li > .sub-menu').slideToggle('slow')
//      console.log( $(this))
      $(this).siblings('.wstbrandbottom').slideToggle('slow')
      return false
    })
    $(window).on('resize', function () {
      if ($(window).outerWidth() < 992) {
        $('.wsmenu').css('height', $(this).height() + 'px')
        $('.wsmenucontainer').css('min-width', $(this).width() + 'px')
      } else {
        $('.wsmenu').removeAttr('style')
        $('.wsmenucontainer').removeAttr('style')
        $('body').removeClass('wsactive')
        $('.wsmenu > .wsmenu-list > li > .wsmegamenu, .wsmenu > .wsmenu-list > li > ul.sub-menu, .wsmenu > .wsmenu-list > li > ul.sub-menu > li > ul.sub-menu, .wsmenu > .wsmenu-list > li > ul.sub-menu > li > ul.sub-menu > li > ul.sub-menu').removeAttr('style')
        $('.wsmenu-click').removeClass('ws-activearrow')
        $('.wsmenu-click02 > i').removeClass('wsmenu-rotate')
      }
    })
    $(window).trigger('resize')
  })

  $(window).ready(function () {
    $('.wsshoptabing.wtsbrandmenu > .wsshoptabingwp > .wstabitem02 > li').on('mouseenter', function () {
      $(this).addClass('wsshoplink-active').siblings(this).removeClass('wsshoplink-active')
      return false
    })
  })

  setHeights()
  $(window).on('load resize', function () {
    var _0x50b6x1 = $(window).width()
    if (_0x50b6x1 <= 991) {
      $('.wsshopwp').css('height', '100%')
      $('.wstitemright').css('height', '100%')
    } else {
      setHeights()
    }
  })

  function setHeights() {
    var height = 1
    $('.wstabitem > li').each(function () {
      var wsirInnerHeight = $(this).find('.wstitemright').innerHeight()
      height = wsirInnerHeight > height ? wsirInnerHeight : height
      $(this).find('.wstitemright').css('height', 'auto')
    })
    $('.wsshopwp').css('height', height + 0)
    // $('.wsshopwp').css('height', 'auto');
  }

  $(document).ready(function ($) {
    function hideItems() {
      if ($(window).width() >= 991) {
        $('.wsshoptabing, .wstitemright, .wstbrandbottom').css({'display': ''})
      }
    }

    hideItems()
    $(window).resize(hideItems)
  })
})